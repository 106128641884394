// Contants
import { GUIDES, PACKAGES } from '@/constants'
// Components
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import YoutubeVideoPlayer from '@/components/ui/YoutubeVideoPlayer'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// Mixins
import intercomMixin from '@/mixins/intercomMixin'
import uiMixin from '@/mixins/uiMixin'
// Filters
import { formatPrice, sanitizeHtmlContent } from '@/filters'
// Services
import { getCustomViewById } from '@/services/customViews'
// Utils
import { get, isNil } from 'lodash'
import { isYoutubeUrl } from '@/utils'

export default {
  name: 'PlansGuide',
  components: {
    VuetifyContentLoading,
    VuetifyToolBar,
    YoutubeVideoPlayer
  },
  filters: { formatPrice },
  mixins: [intercomMixin, uiMixin],
  data() {
    return {
      guideItems: [],
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData']),
    ...mapGetters('company', ['trialPlan']),
    ...mapGetters('place', ['subscribedPackages']),
    /**
     * Plan actual del usuario
     *
     * @return {string}
     */
    currentPlan() {
      return get(this.subscribedPackages, '[0].packageId', PACKAGES.free.value)
    },
    /**
     * Elementos de la guía completados por el usuario
     *
     * @return {array}
     */
    guideItemsCompleted() {
      return get(this.userData, 'guideItemsCompleted', [])
    },
    /**
     * Título y subtítulo a mostrar en la cabecera de la guía
     *
     * @return {String}
     */
    guideTitles() {
      return this.trialPlan && this.trialPlan.daysLeft > -1
        ? {
            title: `Todo lo que puedes hacer con tu plan ${PACKAGES[this.currentPlan].label}`,
            subtitle: `Conoce cada aspecto de tu plan para sacar el máximo partido a
              Bakarta ${PACKAGES[this.currentPlan].label}`
          }
        : {
            title: `Te damos la bienvenida al plan ${PACKAGES[this.currentPlan].label}`,
            subtitle: `Desde Bakarta te queremos dar la enhorabuena por dar el paso hacia
              la digitalización de tu negocio. Aquí encontrarás toda la
              información que necesitas para sacar el máximo partido a tu plan ${
                PACKAGES[this.currentPlan].label
              }`
          }
    },
    /**
     * Items de la guía parseados (están "checkeados")
     *
     * @return  {array}
     */
    parsedGuideItems() {
      const guideItemsCompleted = this.guideItemsCompleted

      this.guideItems.map((item, index) => {
        this.guideItems[index].checked = guideItemsCompleted.indexOf(item.id) > -1
        this.guideItems[index].loading = false // Desactivamos los loading "activos"
      })

      return this.guideItems
    },
    /**
     * Título de la sección
     *
     * @return {string}
     */
    toolBarTitle() {
      return !isNil(this.currentPlan) ? `Descubre el plan ${PACKAGES[this.currentPlan].label}` : ''
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    ...mapActions('authentication', ['updateUser']),
    /**
     * Cuando expandimos o contraemos el contenido del item
     *
     * @param {object} item
     */
    handleClickExpand(item) {
      item.expand = !item.expand
    },
    /**
     * Cuando pulsamos sobre "ir a funcionalidad"
     *
     * @param {object} item
     */
    handleClickGoToFunctionality(item) {
      // Marcamos en Intercom
      this.setIntercomGuideItem(item.id)
      // Actualizamo estado del item
      this.updateUser({
        id: this.userData.id,
        guideItemsCompleted: [...this.guideItemsCompleted, item.id]
      })
      // Nos movemos a la URL
      this.routerPushTo({
        path: item.path
      })
    },
    /**
     * Cuando pulsamos en "Entendido"
     *
     * @param {string} id
     */
    async handleClickUnderstood(id) {
      if (this.guideItemsCompleted.indexOf(id) === -1) {
        try {
          // Loading
          this.modifyAppLoadingLayer({ visible: true, translucid: true })
          // Marcamos en Intercom
          this.setIntercomGuideItem(id)
          // Actualizamo estado del item
          await this.updateUser({
            id: this.userData.id,
            guideItemsCompleted: [...this.guideItemsCompleted, id]
          })
        } catch (err) {
          this.modifyAppAlert({
            text: err,
            type: 'error',
            visible: true
          })
        } finally {
          // Loading
          this.modifyAppLoadingLayer({ visible: false })
        }
      }
    },
    /**
     * Cuando pulsamos en "ver video"
     */
    handleClickVideo(item) {
      this.modifyAppDialog({
        title: item.title,
        contentComponent: YoutubeVideoPlayer,
        contentComponentProps: {
          url: item.media
        },
        optionsActionsButtons: {
          acceptButtonHide: true,
          cancelButtonText: 'Cerrar'
        },
        visible: true
      })
    },
    /**
     * Obtenemos todos los datos necesarios para cargar
     * el componente (vista) correctamente
     */
    async getEveryNeededData() {
      try {
        // Si no posees ningún plan, lo devolvemos al
        // Obtenemos "items" de la guía
        await this.setGuideItems()
      } catch (error) {
        // Show error
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Obtenemos los items de la guía según el plan
     * que el usuario ha obtenido
     */
    async setGuideItems() {
      if (!isNil(this.currentPlan) && !isNil(GUIDES[this.currentPlan])) {
        const customViews = await Promise.all(
          GUIDES[this.currentPlan].map(async (item) => {
            const customView = await getCustomViewById(item.id)
            return {
              ...customView,
              ...item,
              expand: false,
              isYoutubeVideo:
                isNil(customView) || isNil(customView.media)
                  ? false
                  : isYoutubeUrl(customView.media)
            }
          })
        )

        // Solo nos quedamos con aquellos que poseen "title"
        this.guideItems = customViews.filter((view) => view.title)
      }
    },
    /**
     * Cadena "limpiada"
     *
     * @param {string}
     * @return {string}
     */
    sanitizeString(str) {
      return sanitizeHtmlContent(str)
    },
    /**
     * Marcamos en Intercom el item completado
     *
     * @param {string} id
     */
    setIntercomGuideItem(id) {
      this.intercomUpdate({ [`bakarta_guide_${id}`]: true })
    }
  }
}
